<template>
  <v-main class="cases">
    <div class="app-container pt-10 pb-3">
      <section
        class="cases__header d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="cases__text">
          <h1 class="text-h4 font-weight-bold">Special Audit</h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            Here you can view cases.
          </p>
        </div>

        <div class="d-flex justify-start align-center">
          <a
            v-if="!isMobile"
            class="cases__header__filter-btn d-flex align-center justify-center"
            :class="{ 'cases__header__filter-btn--active': isFilterActive }"
            @click="onFilterClick"
          >
            <v-icon color="black">mdi-filter-variant</v-icon>
          </a>
          <v-badge
            v-else
            :value="filterBadgeContent"
            :content="filterBadgeContent"
            offset-x="15"
            offset-y="20"
            color="primary"
          >
            <a
              class="cases__header__filter-btn d-flex align-center justify-center"
              @click="onFilterClick"
            >
              <v-icon color="black">mdi-filter-variant</v-icon>
            </a>
          </v-badge>

          <v-btn
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-2"
            @click="createBatchModal = true"
          >
            Create Audit
          </v-btn>
        </div>
      </section>

      <section class="cases__table">
        <v-skeleton-loader
          v-if="firstLoading"
          :loading="loading"
          type="table"
        />
        <v-data-table
          v-show="!firstLoading"
          calculate-widths
          :headers="headers"
          :items="fullCasesData"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalCases"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          :mobile-breakpoint="0"
          multi-sort
          @update:options="handleTableUpdate"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template
            v-if="isFilterActive && !isMobile && filtersOptions"
            v-slot:top
          >
            <v-row class="d-lfex align-center mb-3">
              <v-col class="d-flex" cols="2">
                <SearchInput
                  :value="filters.caseReference"
                  label="Search Case Id+"
                  @change="handleFilterChange($event, 'caseReference')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <CliniciansSelectSearch
                  :values="filters.clinician"
                  :label="'Clinician'"
                  :item-value="'_id'"
                  @change="handleFilterChange($event, 'clinician')"
                />
              </v-col>

              <v-col class="d-flex" cols="5">
                <ClinicalCodesMultiselect
                  :value="filters.clinicalCodes"
                  @change="handleFilterChange($event, 'clinicalCodes')"
                />
              </v-col>

              <v-col class="d-flex" cols="2">
                <RangePicker
                  :value="filters.age"
                  @change="handleFilterChange($event, 'age')"
                />
              </v-col>

              <v-col class="d-flex" cols="2">
                <v-autocomplete
                  :value="filters.sex"
                  :items="filtersOptions.sex"
                  label="Sex"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'sex')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters.locationName"
                  :items="filtersOptions.locationName"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Location"
                  hide-details
                  clearable
                  outlined
                  class="case-status-select"
                  @change="handleFilterChange($event, 'locationName')"
                />
              </v-col>
              <v-col class="d-flex" cols="4">
                <ClinicalServicesMultiselect
                  :services="filters.clinicalService"
                  @change="handleFilterChange($event, 'clinicalService')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters.source"
                  :items="filtersOptions.source"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Source"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'source')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters.caseType"
                  :items="filtersOptions.caseType"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Case Type"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'caseType')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <TimeDataPicker
                  ref-name="menu1"
                  :date="filters.caseDate"
                  label="Case Date"
                  @change="handleFilterChange($event, 'caseDate')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <TimeDataPicker
                  ref-name="menu2"
                  :date="filters.createdAt"
                  label="Import Date"
                  @change="handleFilterChange($event, 'createdAt')"
                />
              </v-col>
              <v-row>
                <v-col class="d-flex justify-center ml-12" cols="1">
                  <CancelButton @click="onFilterCancel" />
                </v-col>
                <v-col class="d-flex justify-center ml-16" cols="1">
                  <ApplyButton @click="fetchCases" />
                </v-col>
              </v-row>
            </v-row>
          </template>

          <template v-slot:item="{ item }">
            <tr
              :class="{ 'additional-audit-case': isAuditAdditional(item) }"
              @click="goToCase(item)"
            >
              <td style="min-width: 140px">
                {{ item.caseReference }}
              </td>
              <td style="min-width: 120px">
                {{ item.source }}
              </td>
              <td style="min-width: 130px">
                <div class="d-flex flex-column flex-start align-start">
                  <span v-if="item?.consultation?.clinicianId">
                    {{ item.consultation.clinicianId?.name }}
                  </span>
                  <span v-if="item?.advice?.clinicianId">
                    {{ item.advice.clinicianId?.name }}
                  </span>
                </div>
              </td>
              <td style="max-width: 120px">
                <span class="d-block text-truncate">
                  {{ get(item, "clinicalService.name", "-") }}
                </span>
              </td>
              <td style="max-width: 120px">
                <span class="d-block text-truncate">
                  {{ get(item, "prescriptions", "-") }}
                </span>
              </td>
              <td style="min-width: 125px">
                {{
                  get(item, "clinicalCodes", [])
                    .map(({ code }) => code)
                    .join(", ")
                }}
              </td>
              <td style="min-width: 80px">
                {{ item.age }}
              </td>
              <td style="min-width: 100px">
                {{ item.sex }}
              </td>
              <td>{{ item?.locationName || "-" }}</td>
              <td>
                {{ getPreviousAudit(item) }}
              </td>
              <td style="min-width: 120px">
                <span>{{ dateToLocalString(item.caseDate, false) }}</span>
              </td>
              <td style="min-width: 135px">
                <span>{{ dateToLocalString(item.createdAt, false) }}</span>
              </td>
            </tr>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          :value="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>

      <v-dialog
        v-if="isMobile && filtersOptions"
        v-model="isFilterActive"
        fullscreen
        persistent
      >
        <v-card flat class="cases__filter-menu">
          <div
            class="app-container pb-4 d-flex flex-column justify-space-between"
          >
            <div class="cases__filter-menu__filters pt-10">
              <h1 class="text-h4 font-weight-bold pb-5">Filters</h1>

              <SearchInput
                :value="filters.caseReference"
                label="Search Case Id+"
                @change="handleFilterChange($event, 'caseReference')"
              />
              <br />
              <CliniciansSelectSearch
                :values="filters.clinician"
                :label="'Clinician'"
                :item-value="'name'"
                @change="handleFilterChange($event, 'clinician')"
              />
              <br />
              <br />
              <ClinicalCodesMultiselect
                :value="filters.clinicalCodes"
                @change="handleFilterChange($event, 'clinicalCodes')"
              />
              <br />
              <v-autocomplete
                :value="filters.age"
                :items="filtersOptions.age"
                label="Age"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'age')"
              />
              <br />
              <v-autocomplete
                :value="filters.sex"
                :items="filtersOptions.sex"
                label="Sex"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'sex')"
              />
              <br />
              <v-select
                :value="filters.locationName"
                :items="filtersOptions.locationName"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Location"
                hide-details
                clearable
                outlined
                class="case-status-select"
                @change="handleFilterChange($event, 'locationName')"
              />
              <br />
              <v-select
                :value="filters.caseType"
                :items="filtersOptions.caseType"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Case Type"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'caseType')"
              />
              <br />
              <v-select
                :value="filters.source"
                :items="filtersOptions.source"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Source"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'source')"
              />
              <br />
              <ClinicalServicesMultiselect
                :services="filters.clinicalService"
                @change="handleFilterChange($event, 'clinicalService')"
              />
              <br />
              <TimeDataPicker
                ref-name="menu1"
                :date="filters.caseDate"
                label="Case Date"
                @change="handleFilterChange($event, 'caseDate')"
              />
              <br />
              <TimeDataPicker
                ref-name="menu2"
                :date="filters.createdAt"
                label="Import Date"
                @change="handleFilterChange($event, 'createdAt')"
              />
            </div>

            <div class="cases__filter-menu__controls d-flex flex-column">
              <ApplyButton large class="mb-5" @click="fetchCases" />
              <CancelButton large @click="onFilterCancel" />
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <create-batch-modal
      :dialog="createBatchModal"
      :batch-type="defaultBatchType"
      :clinical-services="clinicalServices"
      @toggle="onBatchModalToggle"
    />
  </v-main>
</template>

<script>
import dateToLocalString from "@/helpers/dateToLocalString";
import { get } from "@/helpers/get";
import { getPreviousAudit, isAuditAdditional } from "@/helpers/audit";
import SearchInput from "@/components/controls/SearchInput.vue";
import CreateBatchModal from "@/components/batch/CreateBatchModal.vue";
import RangePicker from "@/components/controls/RangePicker.vue";
import CliniciansSelectSearch from "@/components/controls/CliniciansSelectSearch.vue";
import ClinicalCodesMultiselect from "@/components/controls/ClinicalCodesMultiselect.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import { getCases, getSelectOptions } from "@/services/cases";
import {
  TableRowsPerPage,
  CaseAuditTypes,
  CasesStatusesIcons,
  BatchTypes,
  AuditCaseViews,
} from "@/misc/constants";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";
import { getClinicalServices } from "@/services/clinicalServices";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";

export default {
  name: "SpecialAuditPage",

  components: {
    ClinicalServicesMultiselect,
    SearchInput,
    CreateBatchModal,
    CliniciansSelectSearch,
    TimeDataPicker,
    RangePicker,
    ClinicalCodesMultiselect,
    CancelButton,
    ApplyButton,
  },

  data() {
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };
    const headers = [
      {
        text: "Case Reference",
        align: "start",
        value: "caseReference",
        sortable: false,
      },
      {
        text: "Source",
        value: "source",
        sortable: false,
      },
      {
        text: "Clinician Name",
        value: "clinicians",
        sortable: false,
      },
      {
        text: "Service",
        value: "clinicalService,name",
        sortable: false,
      },
      {
        text: "Prescriptions",
        value: "prescriptions",
        sortable: false,
      },
      {
        text: "Clinical Codes",
        value: "clinicalCodes",
        sortable: false,
      },
      {
        text: "Age",
        value: "age",
      },
      {
        text: "Gender",
        value: "sex",
      },
      {
        text: "Location",
        value: "locationName",
        sortable: false,
      },
      {
        text: "Audit",
        value: "audit",
        sortable: false,
      },
      { text: "Case Date", value: "caseDate" },
      {
        text: "Import date",
        value: "createdAt",
      },
    ];

    return {
      loading: true,
      firstLoading: true,
      options: {},
      totalCases: null,
      tableFooterOptions,
      isFilterActive: false,
      headers,
      fullCasesData: [],
      filtersOptions: null,
      CasesStatusesIcons,
      filters: {
        caseReference: null,
        locationName: null,
        clinician: null,
        age: null,
        sex: null,
        caseDate: null,
        createdAt: null,
        caseType: null,
        clinicalCodes: null,
        clinicalService: null,
        source: null,
      },
      importModal: false,
      createBatchModal: false,
      defaultBatchType: BatchTypes.specialAudit,
      clinicalServices: [],
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isFilterButtonActive() {
      return Object.values(this.filters).some((val) => !!val === true);
    },
    filterBadgeContent() {
      return Object.keys(this.filters).reduce(
        (acc, key) => acc + !!this.filters[key],
        0
      );
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalCases / this.options.itemsPerPage);
    },
  },

  async mounted() {
    const [options] = await Promise.all([
      getSelectOptions("locationName,caseType,age,sex,source"),
      this.fetchClinicalServices(),
    ]);
    this.filtersOptions = options;
  },

  methods: {
    isAuditAdditional,
    getPreviousAudit,
    get,
    dateToLocalString,
    async fetchCases() {
      try {
        this.loading = true;
        const query = this.buildQuery();
        const { data, total } = await getCases(query);
        this.totalCases = total;
        this.fullCasesData = data;
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch cases",
          text: e?.response?.data?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;
      queryParams.batchType = BatchTypes.specialAudit;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }

      const filtersQuery = this.buildQueryFilter();

      return { ...queryParams, ...(filtersQuery ?? {}) };
    },
    buildQueryFilter() {
      const isFiltersNotEmpty = Object.values(this.filters).some(
        (val) => !!val === true
      );
      const query = {
        populate: [
          { path: "clinicalCodes" },
          { path: "clinicalService", select: "name" },
          { path: "specialAudits.auditId", select: "executionOrder createdAt" },
          { path: "specialAudits.batchId", select: "name" },
        ],
      };

      if (isFiltersNotEmpty) {
        const filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            switch (key) {
              case "caseDate":
              case "createdAt": {
                const [first, second] = this.filters[key];
                const startDate = new Date(first);
                const endDate = second
                  ? new Date(second)
                  : new Date(startDate.getTime() + 86400000);
                filters[key] = { $gte: startDate, $lt: endDate };
                break;
              }
              case "age": {
                const data = this.filters[key]
                  .split("-")
                  .map((el) => Number(el));
                if (data[1]) {
                  filters[key] = { $gte: data[0], $lte: data[1] || 100 };
                } else {
                  filters[key] = data[0];
                }
                break;
              }
              case "caseReference":
                filters[key] = { $regex: this.filters[key] };
                break;
              case "clinicalService":
                filters[key] = { $in: this.filters[key] };
                break;
              case "clinician": {
                const complexKey = "$or";
                filters[complexKey] = [
                  { "advice.clinicianId": this.filters[key] },
                  { "consultation.clinicianId": this.filters[key] },
                ];
                break;
              }
              case "clinicalCodes":
                filters[key] = { $in: this.filters[key] };
                break;
              default:
                filters[key] = this.filters[key];
            }
          }
        });

        query.filter = filters;
      }

      if (this.$route?.query?.specialAudit && !isFiltersNotEmpty) {
        query.filter = {
          specialAudits: {
            $elemMatch: {
              batchId: { $exists: false },
            },
          },
        };
      }

      return Object.keys(query).length ? query : null;
    },
    async handleTableUpdate() {
      await this.fetchCases();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    onFilterClick() {
      this.isFilterActive = !this.isFilterActive;
    },
    goToCase({ _id }) {
      this.$router.push({
        path: `cases/${_id}`,
        query: {
          caseType: CaseAuditTypes.all,
          caseView: AuditCaseViews.special,
        },
      });
    },
    async onFilterCancel() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
      await this.fetchCases();
    },
    onBatchModalToggle(value) {
      this.createBatchModal = value;
    },
    async handleFilterChange(value, key) {
      this.filters[key] =
        Array.isArray(value) && !value.length
          ? null
          : typeof value === "string" && !value.length
          ? null
          : value;
      this.options.page = 1;
    },
    async fetchClinicalServices() {
      try {
        this.clinicalServices = await getClinicalServices();
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Service fetch failed",
          text: error.response?.data?.message || error.message,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.cases {
  .additional-audit-case {
    background-color: #fff2f1;
  }

  .v-data-table__wrapper {
    overflow-x: auto;

    @media (max-width: 1600px) {
      overflow-y: hidden;
    }
  }

  &__header {
    &__filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }

  &__filter-menu {
    & .app-container {
      height: 100vh;
      padding-top: #{$headerHeight};
    }
  }
}
</style>
